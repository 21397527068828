import { DEBUG } from './debug'

const HOST = DEBUG ? '' : 'https://matrica-sudby.ru/lk'
const HOST_DOWNLOAD = DEBUG
	? 'https://matrica-sudby.ru/download'
	: 'https://matrica-sudby.ru/download'
const HOST_HOME = DEBUG ? '/' : 'https://matrica-sudby.ru/'
console.log('HOST', HOST)
console.log('HOST_HOME', HOST_HOME)
export { HOST, HOST_HOME, HOST_DOWNLOAD }
